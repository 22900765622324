import ProgressionBar from "../../_AppElements/ProgressionBar/ProgressionBar";
import {TitleH5} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";
import {getSkillTranslation} from "./util";


function OneSkillSub({
                       skill,
                       color,
                       ...props
                     }) {

  const { t } = useTranslation();

  const {name, type, level} = skill;


  return (
    <div id={'skills'} className={'skills flex w-full xs:w-[48%] md:w-1/2'}>
      <TitleH5 className={'hidden md:!block md:w-4/12 text-right pr-4'}>
        {getSkillTranslation(name,t)}
      </TitleH5>

      <div className={'w-full md:!w-8/12 flex flex-col justify-center h-14'}>
        <ProgressionBar level={level} color={color}>
          {getSkillTranslation(name,t)}
        </ProgressionBar>
      </div>

    </div>
  );
}


export default OneSkillSub;
