import ProgressionBar from "../../_AppElements/ProgressionBar/ProgressionBar";
import OneSkillSub from "./OneSkillSub";
import SubSkillsGroup from "./SubSkillsGroup";
import {DivDefault, isEmpty, TitleH4, transitionFadeIn} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";
import {getSkillTranslation} from "./util";


function OneSkill({
                    skill,
                    color,
                    isParentMode = true,
                    isOverviewMode = false,
                    skillSearch = '',
                    ...props
                  }) {

  const { t } = useTranslation();

  let {name, level} = skill;
  let {children} = skill

  if (children == null) {
    children = []
  }

  function getChildrenWithoutLevel() {
    return children.filter((child) => ((child.level === 0) || (child.level === undefined)))
  }

  let childrenWithLevel = children.filter((child) => (child.level > 0))

  if (isOverviewMode && skillSearch.length === 0)
    childrenWithLevel = childrenWithLevel.filter((skill) => skill.isPinned)


  return (
    <DivDefault id={name}
                className={`skills uppercase !my-8 ${isEmpty(children) ? '!py-0' : ''}`}
                {...transitionFadeIn}
                {...props}
                //TODO: onMouseHover display tooltip
    >
      {
        level ?
        (
          <>
            <div className={'flex'}>
              <TitleH4 className={'hidden md:!block w-2/12 py-4'} isMarginEnabled={false}>
                {getSkillTranslation(name,t)}
              </TitleH4>

              <div className={'w-full md:!w-10/12 flex flex-col justify-center h-16'}>
                <ProgressionBar level={level} color={color}>
                  {getSkillTranslation(name,t)}
                </ProgressionBar>
              </div>
            </div>

            {!isParentMode &&
              childrenWithLevel.map((skillChild) => (
                <OneSkill skill={skillChild}
                          color={color}
                          key={skillChild.id}
                />
              ))
            }
          </>

        )
        :
        (
          <div className={'flex'}>
            <TitleH4 className={'block py-5'}>
              {getSkillTranslation(name,t)}
            </TitleH4>

          </div>
        )

      }

      {isParentMode &&
        <div className={'flex flex-wrap justify-between'}>
          {childrenWithLevel.map((skillChild) => (
            <OneSkillSub skill={skillChild}
                         color={color}
                         key={skillChild.id}
            />
          ))}
        </div>
      }

      {
        !isEmpty(getChildrenWithoutLevel())
        &&
        !isOverviewMode
        &&
        (
          <div className={'flex flex-wrap md:justify-end'}>
            <SubSkillsGroup subskills={getChildrenWithoutLevel()}
                            color={color}
            />
          </div>
        )
      }

    </DivDefault>
  );
}


export default OneSkill;
