import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {APP_DEFAULT_LANGUAGE} from "../../constants/AppConstants";
import {getBrowserLanguage} from "../../helpers/Browser";

const i18nInitialization = i18n.createInstance();

i18nInitialization.use(HttpApi)
  .use(initReactI18next)
  .init({
      supportedLngs: ['en', 'fr'],
      lng: getBrowserLanguage(true) || APP_DEFAULT_LANGUAGE,
      fallbackLng: APP_DEFAULT_LANGUAGE,
      ns:['common', 'experiences', 'projects', 'services', 'skills'],
      defaultNS: 'common',
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      },
      react: {
        useSuspense: true
      },
      interpolation: {
        escapeValue: false
      }
    },
    (err, t) => {
      if (err) console.error('Erreur de chargement i18n:', err);
      else console.log('Initialisation i18n réussie');
    })

export {i18nInitialization};