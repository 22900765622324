import React from "react";

import {DivDefault, transitionFadeIn} from "@florian-cassagne/simple-toolbox";
import parse from "html-react-parser";


function ViewerDescriptionShort({children}) {
  return (
    <>
      <DivDefault className={'extern-html'}
                  {...transitionFadeIn}>
        {parse(children)}
      </DivDefault>
    </>
  );
}


export default ViewerDescriptionShort;
