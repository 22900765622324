import {DivDefault, Section} from "@florian-cassagne/simple-toolbox";
import {getSkillTranslation} from "./util";
import {useTranslation} from "react-i18next";

function SubSkillsGroup({
                          subskills,
                          color,
                          ...props
                        }) {

  const { t } = useTranslation();

  return (
    <DivDefault className={'flex uppercase md:w-10/12'}>
      {subskills.map((skill) => (
        <Section style={{backgroundColor: color}}
                 key={skill.name}>
          {getSkillTranslation(skill.name,t)}
        </Section>
      ))}
    </DivDefault>
  );
}


export default SubSkillsGroup;
