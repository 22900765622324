import 'react-multi-carousel/lib/styles.css';


import 'config.js';
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import ViewerDescriptionShort from "../../_AppElements/Viewer/ViewerDescriptionShort";
import ViewerDescriptionLong from "../../_AppElements/Viewer/ViewerDescriptionLong";
import ViewerLinks from "../../_AppElements/Viewer/ViewerLinks";
import ViewerCarousel from "../../_AppElements/Viewer/ViewerCarousel";
import {DivDefault, getDateTextYYYYMM, HTMLSection, Separator, TitleH1} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

function OneServiceDetailed({
                              service,
                              setProject,
                              ...props
                            }) {

  const { t } = useTranslation();

  const [descriptionLong] = useState("")

  console.log(useParams())

  return (
    <HTMLSection>
      <TitleH1 transitionEnabled={false}>{t(service.title, {ns:'services'})}</TitleH1>

      <ViewerDescriptionShort>{t(service.descriptionShort, {ns:'services'})}</ViewerDescriptionShort>
      <Separator isVisible={false}/>

      <DivDefault className={'flex flex-wrap my-4 text-center text-lg sm:text-xl'} transitionEnabled={true}>
        <p className={'w-full md:w-1/2 mb-2 md:mb-0'}>
          <FontAwesomeIcon icon={faCalendarDays}/>&nbsp;&nbsp;
          <span className={'capitalize'}>Depuis {getDateTextYYYYMM(service.dateStart)}</span>
        </p>
      </DivDefault>
      <Separator isVisible={false}/>

      <ViewerCarousel
        srcBase={'/data/services/' + service.id}
        workType={'services'}
      >
        {service.images}
      </ViewerCarousel>
      <Separator isVisible={false}/>

      <ViewerDescriptionLong>
        {descriptionLong}
      </ViewerDescriptionLong>
      <Separator isVisible={false}/>

      <ViewerLinks>{service.links}</ViewerLinks>


    </HTMLSection>
  );
}

export default OneServiceDetailed;