import React from 'react';
import '../Skills/Skills';
import {ROUTE_NAMES, ROUTE_PATHS} from "../../utils/RouteNames";
import {NavItem} from "./Nav";
import NavLanguageSwitcher from "./Nav/NavLanguageSwitcher";
import {useTranslation} from "react-i18next";

function NavItems({
                    isSmallScreen = false,
                    ...props
}) {
  const { t, i18n} = useTranslation();

  return (
    <>
      <NavItem id={ROUTE_NAMES.HOME} link={ROUTE_PATHS.HOME}>
        {t('menu.homepage')}
      </NavItem>
      <NavItem id={ROUTE_NAMES.PORTFOLIO} link={ROUTE_PATHS.PORTFOLIO}>
        {t('menu.portfolio')}
      </NavItem>
      <NavItem id={ROUTE_NAMES.SKILLS} link={ROUTE_PATHS.SKILLS}>
        {t('menu.skills')}
      </NavItem>
      { isSmallScreen && (
        <NavItem>
          <NavLanguageSwitcher/>
        </NavItem>
      )}
    </>
  );
}


export default NavItems;
