import React from "react";
import 'react-multi-carousel/lib/styles.css';

import {ANIMATION_DEFAULT, ElementCarousel, Image} from "@florian-cassagne/simple-toolbox"
import {useTranslation} from "react-i18next";

const {updateCarouselItemsOpacity} = ElementCarousel


function CarouselDefault({
                           images,
                           srcBase,
                           workType = '',
                           ...props
                         }) {

  const { t } = useTranslation();

  return (
    <ElementCarousel {...props}>
      {images.map((image, index) => (
          <div className={ANIMATION_DEFAULT + ' m-1'}
               onLoad={updateCarouselItemsOpacity}
               key={index}
          >
            <Image src={srcBase + '/' + image.link}
                   alt={t(image.alt, {ns : workType}) || t(image.title, {ns : workType}) || ''}
                   title={t(image.title, {ns : workType}) || t(image.alt, {ns : workType}) || ''}/>
            <p className={'mt-2 text-center'}>
              {t(image.title, {ns : workType}) || ''}
            </p>
          </div>
        )
      )}
    </ElementCarousel>
  );
}


export default CarouselDefault;
