import React from "react";
import {DivDefault, ButtonLink} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";

function ButtonGroupLink({
                           links,
                           className,
                           workType = '',
                           ...props
                         }) {
  const { t } = useTranslation(['experiences', 'common']);

  return (
    <DivDefault {...props}
                className={'flex flex-wrap justify-center'}>
      {links?.map((link, index) => (
        <ButtonLink href={link.url}
                    className={'mr-2 lg:mr-4'}
                    key={index}>
          {t(link.name)}
        </ButtonLink>
      ))}
    </DivDefault>
  )
}


export default ButtonGroupLink;
