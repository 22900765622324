import React, {Suspense, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import dataProjects from "../data/projects.json";
import {useParams} from "react-router-dom";
import {ScrollToTop} from "@florian-cassagne/simple-toolbox";
import {OneProjectDetailed} from "./PagesLazyLoad";
import {getSkillFromId} from "../utils/SkillType";
import {useTranslation} from "react-i18next";
import LoadingSpinner from "../components/_AppElements/LoadingSpinner";

function PageOneProject() {
  const {t, i18n} = useTranslation(['projects']);

  const {project_id, lng} = useParams();

  const [project, setProject] = useState(null);

  useEffect(() => {
    const projectData = dataProjects.projects.filter((project) => project.id === project_id)[0]
    setProject(projectData)
    if (lng) i18n.changeLanguage(lng)
  }, [project])

  function getTitleFirstSkills() {
    let firstSkills = project.skills.slice(0, 2)
    return firstSkills.map((skill) => getSkillFromId(skill).name || skill)
  }


  if (!project) {
    return <LoadingSpinner/>
  }

  return (
    <>
      <Helmet>
        <title>
          {t(project.title) + ' - ' + getTitleFirstSkills().join(', ')} - Florian C</title>
        <meta name="description" content={t(project.descriptionShort)}/>
      </Helmet>
      <ScrollToTop/>
      <Suspense fallback={<LoadingSpinner/>}>
        <OneProjectDetailed project={project} setProject={setProject}/>
      </Suspense>
    </>
  );
}


export default PageOneProject;
