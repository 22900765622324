import 'aos/dist/aos.css';
import '@florian-cassagne/simple-toolbox/dist/style.css';

import './components/Skills/Skills';
import Header from "./components/Header/Header";
import Content from "./components/Content";
import Footer from "./components/Footer/Footer";
import {Nav} from "./components/_AppElements/Nav";

import AOS from 'aos';
import React, {useEffect} from "react";
import FooterMarginAdapter from "./components/Footer/FooterMarginAdapter";
import {UpdateNavProvider} from "./components/_AppElements/Provider/UpdateNavProvider";
import {initializeConfig} from "@florian-cassagne/simple-toolbox";

function App() {
  useEffect(() => {
    AOS.init();
    initializeConfig({
      assetsFolder: '@florian-cassagne/simple-toolbox/dist',
      theme: 'light'
    })
  }, [])


  return (
    <div id={'body'} className={'body text-white text-sm xs:text-base xl:text-lg min-w-[300px]'}>
      <Header />
      <UpdateNavProvider>
        <Nav/>
        <Content />
      </UpdateNavProvider>
      <Footer />
    </div>
  );
}


export default App;
