import {createContext, useContext, useState} from "react";

const UpdateNavContext = createContext(null)

export function UpdateNavProvider({children}) {
  const [currentSection, setCurrentSection] = useState("")

  return (
    <div>
      <UpdateNavContext.Provider value={[currentSection, setCurrentSection]}>
        {children}
      </UpdateNavContext.Provider>
    </div>
  );
}


export function useNavState() {
  return useContext(UpdateNavContext)
}

