import React from "react";

import {DivDefault, TitleH3, transitionFadeIn} from "@florian-cassagne/simple-toolbox";
import parse from "html-react-parser";
import {useTranslation} from "react-i18next";


function ViewerDescriptionLong({children}) {

    const { t } = useTranslation();

    return (
        <>
            {children &&
              (<div {...transitionFadeIn}>
                  <TitleH3 className={'!mb-8'}>
                      {t('work.desc')}
                  </TitleH3>
                  <DivDefault className={'extern-html'}>
                      {parse(children)}
                  </DivDefault>
              </div>)
            }
        </>
    );
}


export default ViewerDescriptionLong;
