import React from 'react';
import {ButtonLinkXL, Paragraph, TitleH1} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";
import {ROUTE_PATHS} from "../../utils/RouteNames";
import {Link} from "react-router-dom";

function PageNotFound() {
  const {t} = useTranslation();

  return (
    <>
      <TitleH1>{t('page.error.notFound.title')}</TitleH1>
      <Paragraph>{t('page.error.notFound.description')}</Paragraph>

      <Link to={ROUTE_PATHS.HOME}>
        <ButtonLinkXL href={'#'}>
          {t('page.error.notFound.returnHomepage')}
        </ButtonLinkXL>
      </Link>
    </>
  );
}


export default PageNotFound;
