export function getCSSColorHSLA(hue, saturation, lightness, alpha){
  return `hsla(${hue}, ${saturation || 50}%, ${lightness || 50}%, ${alpha || 1.0})`
}

export const ColorHelper = {
  COLOR_RED: "red",
  COLOR_ORANGE: "orange",
  COLOR_AMBER: "amber",
  COLOR_YELLOW: "yellow",
  COLOR_LIME: "lime",
  COLOR_GREEN: "green",
  COLOR_EMERALD: "emerald",
  COLOR_TEAL: "teal",
  COLOR_CYAN: "cyan",
  COLOR_SKY: "sky",
  COLOR_BLUE: "blue",
  COLOR_INDIGO: "indigo",
  COLOR_VIOLET: "violet",
  COLOR_PURPLE: "purple",
  COLOR_FUCHSIA: "fuchsia",
  COLOR_PINK: "pink",
  COLOR_ROSE: "rose",
  COLOR_GRAY: "gray",
  COLOR_ZINC: "zinc",
  COLOR_STONE: "stone",
  COLOR_NEUTRAL: "neutral",
  COLOR_SLATE: "slate",
}

export function getColorClass(color, intensity, isText){
  let value = isText ? 'text' : 'bg'
  value += `-${color}-${intensity}`
  return value
}