import "./progression-bar.css"
import {useTranslation} from "react-i18next";

function ProgressionBar({
                          children,
                          level,
                          color,
                          className = '',
                          ...props
                        }) {
  const {t} = useTranslation();

  let levelText

  switch (level) {
    case 4:
    case 5:
      levelText = t('misc.level.1')
      break;
    case 6:
      levelText = t('misc.level.2')
      break;
    case 7:
    case 8:
      levelText = t('misc.level.3')
      break;
    case 9:
    case 10:
      levelText = t('misc.level.4')
      break;
    default:
      levelText = t('misc.level.0')
  }

  return (
    <div className={'relative bg-neutral-800 w-full h-1/2 rounded-full ' + className}>
      <div className={'h-full rounded-full'}
           style={{width: level + '0%', backgroundColor: color}}/>
      <div className={'progression-bar__info absolute top-[10%] w-full text-center transition-all duration-500'}>
        <span className={'progression-bar__name transition-all duration-500 md:hidden'}>
          {children}
        </span>
        <span className={'progression-bar__level transition-all duration-500'}>
          {`${t('misc.level')} : ${levelText.toUpperCase()}`}
        </span>
      </div>
    </div>
  );
}


export default ProgressionBar;