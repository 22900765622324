import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Homepage from "../views/Homepage";
import PagePortfolio from "../views/PagePortfolio";
import PageSkills from "../views/PageSkills";
import PageOneProject from "../views/PageOneProject";
import PageOneService from "../views/PageOneService";
import {PREFIX_LANGUAGE, ROUTE_PATHS} from "../utils/RouteNames";
import PageNotFound from "../views/errors/PageNotFound";

function Content() {

  return (
    <div id={'content'} className={'content px-1 xs:px-2 sm:px-5 lg:px-8 xl:w-11/12 bg-black max-w-screen-2xl lg:mx-auto'}>
      <Routes>
        <Route path={PREFIX_LANGUAGE + ROUTE_PATHS.ONE_SERVICE} element={<PageOneService/>} />
        <Route path={ROUTE_PATHS.ONE_SERVICE} element={<PageOneService/>} />
        <Route path={PREFIX_LANGUAGE + ROUTE_PATHS.ONE_PROJECT} element={<PageOneProject/>} />
        <Route path={ROUTE_PATHS.ONE_PROJECT} element={<PageOneProject/>} />
        <Route path={PREFIX_LANGUAGE + ROUTE_PATHS.HOME} element={<Homepage/>} />
        <Route path={ROUTE_PATHS.HOME} element={<Homepage/>} />
        <Route path={PREFIX_LANGUAGE + ROUTE_PATHS.PORTFOLIO} element={<PagePortfolio/>} />
        <Route path={ROUTE_PATHS.PORTFOLIO} element={<PagePortfolio/>} />
        <Route path={PREFIX_LANGUAGE + ROUTE_PATHS.SKILLS} element={<PageSkills/>} />
        <Route path={ROUTE_PATHS.SKILLS} element={<PageSkills/>} />
        <Route path={'*'} element={<PageNotFound/>} />
      </Routes>
    </div>
  );
}


export default Content;
