import React from 'react';

import {Image} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";

export const Languages = {
  FR: "fr",
  EN: "en"
}

function NavLanguageSwitcher() {

  const { i18n } = useTranslation();

  let imgEN = '/img/misc/languages/en.png';
  let imgFR = '/img/misc/languages/fr.png';

  return (
    <div className={'w-10 hover:cursor-pointer mr-3'}>
        <Image className={'transition-all duration-500 !py-0 ' +
          (i18n.language.split('-')[0] === Languages.FR ? 'w-full ' : '!w-0 ')}
               src={imgFR}
               isFancyMode={false}
               onClick={() => i18n.changeLanguage(Languages.EN)}
        />
        <Image className={'transition-all duration-500 !py-0 ' +
          (i18n.language.split('-')[0] !== Languages.FR ? 'w-full ' : '!w-0 ')}
               src={imgEN}
               isFancyMode={false}
               onClick={() => i18n.changeLanguage(Languages.FR)}
        />

    </div>
  );
}


export default NavLanguageSwitcher;
