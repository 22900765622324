import {DivDefault, InputText, Label, transitionFadeIn} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";

function SkillSearch({
                       setSkillSearch,
                       ...props
                     }) {

  const { t } = useTranslation();

  const inputName = 'skill-search'

  return (
    <DivDefault
      {...transitionFadeIn}
      {...props}
      className={'!mb-16'}>
      <form>
        <Label forInput={inputName}>
          {t('skills.search.label')}
        </Label>
        <InputText type={'search'}
                   id={inputName}
                   name={inputName}
                   placeholder={t('skills.search.placeholder')}
                   onInput={(event) => setSkillSearch(event.target.value)}
        />
      </form>
    </DivDefault>
  );
}


export default SkillSearch;
