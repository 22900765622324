import React from "react";

import {isEmpty, TitleH3, transitionFadeIn} from "@florian-cassagne/simple-toolbox";
import CarouselDefault from "../CarouselDefault";
import {useTranslation} from "react-i18next";

function ViewerCarousel({children, srcBase, workType = ''}) {
  const { t } = useTranslation();

  return (
    <div {...transitionFadeIn}>
      {!isEmpty(children) &&
        <TitleH3 className={'text-center'}>
          {t('work.images')}
        </TitleH3>
      }
      <CarouselDefault images={children}
                       srcBase={srcBase}
                       workType={workType}
      />
    </div>
  );
}


export default ViewerCarousel;
