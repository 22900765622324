export const ROUTE_NAMES = {
  HOME: 'home',
  PORTFOLIO: 'portfolio',
  ONE_PROJECT: 'one_project',
  SKILLS: 'skills',
  ABOUT: 'about',
}

export const ROUTE_PATHS = {
  HOME: '/',
  PORTFOLIO: '/portfolio',
  ONE_PROJECT: '/portfolio/:project_id',
  ONE_SERVICE: '/services/:service_id',
  SKILLS: '/skills',
  ABOUT: '/about',
}

export const PREFIX_LANGUAGE = '/:lng'