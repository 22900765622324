import React from "react";

import {TitleH3, transitionFadeIn} from "@florian-cassagne/simple-toolbox";
import ButtonGroupLink from "../ButtonGroupLink";
import {useTranslation} from "react-i18next";


function ViewerLinks({
                       children,
                       workType = ''
}) {
  const { t } = useTranslation();

  return (
    <div {...transitionFadeIn}>
      {children &&
        <TitleH3>
          {t('work.links')}
        </TitleH3>
      }
      <ButtonGroupLink links={children}
                       className={'justify-center'}
                       workType={workType}
      />
    </div>
  );
}


export default ViewerLinks;
