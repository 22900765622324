import '../Skills/Skills';
import {IconLink} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";


function Footer() {
  const { t } = useTranslation();

  let imgLinkedin = '/img/logo/linkedin.png';
  let imgGmail = '/img/logo/gmail.png';

  return (
    <footer id={'footer'} className={'px-4 py-2 mb-16 text-center'}
            data-aos="zoom-in"
            data-aos-duration="1000">
      <abbr title={t('footer.copyright.abbr')} className={'no-underline'}>
        {t('footer.copyright')}
      </abbr>
      <div className={'footer__contact-links flex justify-center my-8'}>
        <IconLink image={imgLinkedin}
                  href={'https://www.linkedin.com/in/florian-cassagne/'}
                  title={t('aboutMe.linkedIn')}
                  target={'_blank'}/>
        <IconLink image={imgGmail}
                  href={'mailto:florian.cassagne.pro@gmail.com'}
                  title={t('aboutMe.mail')}
                  target={'_blank'}/>

        <a id='wot-badge0' className='wot-badge_dark' href='https://www.mywot.com/scorecard/floriancassagne.net?wot_badge=0_black' target='_blank' >
          <div className='wot-logo'></div>
          <div className='wot-shield'></div>
          <p className='wot-secured'>Verified Website</p>
          <div className='wot-vertical'></div>
          <p className='wot-report'>See Report</p>
        </a>

      </div>
    </footer>

  );
}


export default Footer;
