import './header.css';
import '../Skills/Skills';
import {TitleH1, TitleH2, TitleH3} from "@florian-cassagne/simple-toolbox";
import React from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Header() {
  let location = useLocation()

  const { t } = useTranslation();

  return (
    <header id={'header'}
            className={'header py-10 md:!py-16 px-2 sm:!px-5 md:!px-8'}>
      <div className={'pb-4 md:!pb-8 text-center uppercase'}>

        {location.pathname === '/' ?
          <>
            <TitleH1 className={"!text-4xl sm:!text-5xl md:!text-6xl lg:!text-7xl"}
                     transitionEnabled={false}>
              Florian CASSAGNE
            </TitleH1>
            <TitleH2 className={'sm:text-xl md:!text-2xl lg:!text-3xl'}
                     transitionEnabled={false}>
              {t('header.subtitle')}
            </TitleH2>
          </>
          :
          <>
            <TitleH2 className={"text-4xl sm:!text-5xl md:!text-6xl lg:!text-7xl"}
                     transitionEnabled={false}>
              Florian C.
            </TitleH2>
            <TitleH3 className={'sm:!text-xl md:!text-2xl lg:!text-3xl'}>
              {t('header.subtitle')}
            </TitleH3>
          </>
        }

      </div>

      <p className={'md:text-xl lg:text-2xl my-4 w-11/12 mx-auto'}>
        {t('header.biography')}
      </p>

    </header>
  );
}


export default Header;
