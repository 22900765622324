import React, {Suspense, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import dataServices from "../data/services.json";
import {useParams} from "react-router-dom";
import OneServiceDetailed from "../components/Services/OneService/OneServiceDetailed";
import {ScrollToTop} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";
import LoadingSpinner from "../components/_AppElements/LoadingSpinner";

function PageOneService() {
  const { t, i18n } = useTranslation(['services']);

  const {service_id} = useParams();
  const [service, setService] = useState(null);

  useEffect(() => {
    const serviceData = dataServices.services.filter((service) => service.id === service_id)[0]
    setService(serviceData)
  }, [service])

  if (!service) {
    return <LoadingSpinner/>
  }

  return (
    <>
      <Helmet>
        <title>{t(service.title)} - Florian C</title>
        <meta name="description"
              content={t(service.descriptionShort)}/>
      </Helmet>
      <ScrollToTop/>
      <Suspense fallback={<LoadingSpinner/>}>
        <OneServiceDetailed service={service} setService={setService}/>
      </Suspense>
    </>
  );
}


export default PageOneService;
