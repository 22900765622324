import React, {useState} from "react";

function LoadingSpinner() {
  let imgSpinnerPath = '/img/misc/spinner.gif'
  return (
    <img src={imgSpinnerPath}
         alt={'loading spinner'}
         className={'w-8 my-4 mx-auto'}/>
  );
}


export default LoadingSpinner;
