import React, {Suspense} from 'react';
import {Helmet} from 'react-helmet';
import {Projects} from "./PagesLazyLoad";
import {ScrollToTop} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";
import {APP_AUTHOR_NAME_FULL} from "../constants/AppConstants";
import LoadingSpinner from "../components/_AppElements/LoadingSpinner";

function Homepage() {
  const { t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('page.portfolio.meta.title')} - ${APP_AUTHOR_NAME_FULL}`}</title>
        <meta name="description" content={t('page.portfolio.meta.description')} />
      </Helmet>
      <ScrollToTop />
      <Suspense fallback={<LoadingSpinner/>}>
        <Projects/>
      </Suspense>
    </>
  );
}


export default Homepage;
