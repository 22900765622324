import {getCSSColorHSLA} from "../helpers/ColorHelper";

import dataSkills from "../data/skills.json";

export const SKILL_TYPES = {
  WEB_LANGUAGE: 0,
  WEB_FRAMEWORK: 1,
  APP_LANGUAGE: 2,
  APP_FRAMEWORK: 3,
  DATABASE: 4,
  WEBDESIGN: 5,
  WEBMASTER: 6,
  DESIGN: 7,
  TECHNICAL_MISC: 8,
  SOFT_SKILL: 9,
  MISCELLANEOUS: 10,
}


export function getSkillText(skill){
  return skill
}


export function getSkillFromId(skillId){
  let currentSkill;

  currentSkill = dataSkills.skills.filter(skill => skill.id === skillId)

  if(currentSkill.length === 0){
    dataSkills.skills.forEach(skill => {
      skill.children.forEach(child => {
        if (child.id === skillId) {
          currentSkill = child;
        }
      });
    });
  }
  else{
    currentSkill = currentSkill[0]
  }

  return currentSkill
}

export function getColorFromSkill(skillId, saturation, lightness, opacity) {
  saturation = saturation || 50
  lightness = lightness || 50
  opacity = opacity || 1

  if (getSkillFromId(skillId).type === "WEB_LANGUAGE") {
    return getCSSColorHSLA(0, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "APP_LANGUAGE") {
    return getCSSColorHSLA(30, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "DATABASE") {
    return getCSSColorHSLA(60, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "WEB_FRAMEWORK" ) {
    return getCSSColorHSLA(90, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "APP_FRAMEWORK") {
    return getCSSColorHSLA(120, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "TECHNICAL_MISC") {
    return getCSSColorHSLA(150, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "DESIGN") {
    return getCSSColorHSLA(180, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "SOFT_SKILL") {
    return getCSSColorHSLA(210, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "WEBDESIGN") {
    return getCSSColorHSLA(240, saturation, lightness, opacity);
  } else if (getSkillFromId(skillId).type === "WEBMASTER") {
    return getCSSColorHSLA(270, saturation, lightness, opacity)
  } else {
    return getCSSColorHSLA(300, 20, lightness, opacity); // Default color for any other skill or undefined skills
  }
}