import dataSkills from "../../data/skills.json";
import OneSkill from "./OneSkill/OneSkill";
import {getCSSColorHSLA} from "../../helpers/ColorHelper";
import SkillSearch from "./SkillSearch";
import React, {useEffect, useState} from "react";
import {ROUTE_PATHS} from "../../utils/RouteNames";
import {Link} from "react-router-dom";
import {ButtonLinkXL, HTMLSection, Paragraph, TitleH1, TitleH2, useIsScreenMobile, useIsScreenSM} from "@florian-cassagne/simple-toolbox";
import {useTranslation} from "react-i18next";


function Skills({
                  isOverviewMode = false
                }) {
  const {t} = useTranslation();

  const isScreenMobile = useIsScreenMobile()
  const isScreenSM = useIsScreenSM()

  let skillsVisible = dataSkills.skills.filter((skill) => !skill.hidden)

  const [skills, setSkills] = useState(skillsVisible)
  const [skillSearch, setSkillSearch] = useState("")
  const [isParentMode, setParentMode] = useState(true)


  const SECTION_NAME = "skills"

  function skillNamesMatching(skill) {
    let skillName = t(skill.name, {ns: 'skills'})
    return skillName.toLowerCase().includes(skillSearch.toLowerCase())
  }

  function skillTagsMatching(skill) {
    return skill.tags.toLowerCase().includes(skillSearch.toLowerCase())
  }


  if (isOverviewMode && skillSearch.length === 0 ) {
    skillsVisible = skillsVisible.filter((skill) => skill.isPinned)
  }

  useEffect(() => {
    let updatedSkills
    if (skillSearch === "") {
      updatedSkills = skillsVisible
    } else {
      updatedSkills = skillsVisible.filter((skill) =>
        skillNamesMatching(skill)
        ||
        skillTagsMatching(skill)
        ||
        skill.children.some((subSkill) =>
          skillNamesMatching(subSkill)
          ||
          skillTagsMatching(subSkill)
        )
      )
    }

    setSkills(updatedSkills)
  }, [skillSearch])

  return (
    <HTMLSection id={SECTION_NAME} className={SECTION_NAME}>

      {isOverviewMode ?
        <TitleH2>{t('skills.title')}</TitleH2>
        :
        <TitleH1>{t('skills.title')}</TitleH1>
      }

      <Paragraph className={'text-center'}>
        {t('skills.desc')}
      </Paragraph>

      <SkillSearch setSkillSearch={setSkillSearch}/>

      {skills.map((skill, index) => (
        <OneSkill skill={skill}
                  isOverviewMode={isOverviewMode}
                  isParentMode={isParentMode}
                  color={
                    ((isScreenMobile || isScreenSM) && getCSSColorHSLA(index * 35, 70, 30))
                    ||
                    getCSSColorHSLA(index * 35, 60, 45)
                  }
                  skillSearch={skillSearch}
                  key={skill.name}
        />
      ))}

      {isOverviewMode &&
        <Link to={ROUTE_PATHS.SKILLS}>
          <ButtonLinkXL href={'#'}>
            {t('skills.seeMore')}
          </ButtonLinkXL>
        </Link>
      }

    </HTMLSection>
  );
}


export default Skills;
