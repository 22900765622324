import '../Skills/Skills';

function FooterMarginAdapter() {

  return (
    <div className={'lg:mb-8'} />

  );
}


export default FooterMarginAdapter;
